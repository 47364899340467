<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="사업안내" tabActive="사업개요" bg="/images/sub/business/visual.jpg">
                <template #title>사업안내</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>사업개요</template>

            <v-img src="/images/sub/business/overview/overview.jpg" />

            <table-primary :items="items" :headers="headers" class="overview-table">
                <template v-slot:body="{ items }">
                    <tbody>
                        <tr v-for="header in headers" :key="header.text">
                            <th-tertiary>{{ header.text }}</th-tertiary>
                            <td-secondary v-for="item in items" :key="item.text">{{ item[header.value] }}</td-secondary>
                        </tr>
                    </tbody>
                </template>
            </table-primary>
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import TablePrimary from "@/components/publish/parents/tables/table-primary.vue";
import ThTertiary from "@/components/publish/parents/tables/th-tertiary.vue";
import TdSecondary from "@/components/publish/parents/tables/td-secondary.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        TablePrimary,
        ThTertiary,
        TdSecondary,
    },
    data() {
        return {
            headers: [
                { text: "주택위치", value: "주택위치" },
                { text: "공급호수", value: "공급호수" },
                { text: "단지명", value: "단지명" },
                { text: "시행사(임대사업자)", value: "시행사" },
                { text: "시공사", value: "시공사" },
                { text: "입주예정", value: "입주예정" },
            ],
            items: [
                {
                    주택위치: "서울특별시 은평구 불광동 302-13번지 일원 (3호선, 6호선 연신내역 3번 출구)",
                    공급호수: "총 299세대 중 공공지원 민간임대 180세대 (특별공급 36세대, 일반공급 144세대)",
                    단지명: "Lumino 816",
                    시행사: "㈜에이치앤비개발",
                    시공사: "㈜HYD한양, (주)도메인플러스",
                    입주예정: "모집공고 참조",
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.overview-table {
    margin-top: 20px;
    tbody > tr > th {
        width: 30%;
    }
}
@media (min-width: 768px) {
    .overview-table tbody > tr > th {
        width: 15%;
    }
}
@media (min-width: 1024px) {
    .overview-table {
        margin-top: 42px;
    }
}
</style>
