<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-secondary itemTitle="청약신청" bg="/images/form/application/visual.jpg">
                <template #title>청약신청</template>
            </sub-head-secondary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>루미노816 청약신청 조회</template>
            <txt class="mb-20px mb-md-30px">
                <ul>
                    <li class="dot">본 청약의 신청인은 모집공고문을 확인하였으며 모집공고문 미숙지 또는 착오, 신청내역이 사실과 다를 경우 불이익이 발생할 수 있는 점 유의하시기 바랍니다.</li>
                    <li class="dot">신청서 수정은 2025년 01월 09일 23시 59분까지 가능하며 이후부터는 변경 불가능한 점 유의하시기 바랍니다.</li>
                </ul>
            </txt>

            <v-divider style="border-width: 2px 0 0" class="grey darken-4" />
            <v-sheet class="d-flex flex-column" style="flex: 1 0 auto">
                <v-row no-gutters align="center" class="txt txt--sm">
                    <v-col cols="12" md="auto">
                        <div class="w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep">접수일</div>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">{{ application?.createdAt?.toDateTime() }}</div>
                    </v-col>
                </v-row>
                <v-divider />
            </v-sheet>
            <v-row no-gutters>
                <v-col cols="6" class="border-r">
                    <v-sheet class="d-flex flex-column" style="flex: 1 0 auto">
                        <v-row no-gutters align="center" class="txt txt--sm">
                            <v-col cols="12" md="auto">
                                <div class="w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep">이름</div>
                            </v-col>
                            <v-col cols="12" md="auto">
                                <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">{{ application?.name }}</div>
                            </v-col>
                        </v-row>
                        <v-divider />
                    </v-sheet>
                </v-col>
                <v-col cols="6">
                    <v-sheet class="d-flex flex-column" style="flex: 1 0 auto">
                        <v-row no-gutters align="center" class="txt txt--sm">
                            <v-col cols="12" md="auto">
                                <div class="w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep">신청자격</div>
                            </v-col>
                            <v-col cols="12" md="auto">
                                <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">{{ QUALIFICATION_TYPE[application?.qualification_type]?.text }}</div>
                            </v-col>
                        </v-row>
                        <v-divider />
                    </v-sheet>
                </v-col>
                <v-col cols="6" class="border-r">
                    <v-sheet class="d-flex flex-column" style="flex: 1 0 auto">
                        <v-row no-gutters align="center" class="txt txt--sm">
                            <v-col cols="12" md="auto">
                                <div class="w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep">생년월일</div>
                            </v-col>
                            <v-col cols="12" md="auto">
                                <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">{{ birthFormat(application?.birth) }}</div>
                            </v-col>
                        </v-row>
                        <v-divider />
                    </v-sheet>
                </v-col>
                <v-col cols="6">
                    <v-sheet class="d-flex flex-column" style="flex: 1 0 auto">
                        <v-row no-gutters align="center" class="txt txt--sm">
                            <v-col cols="12" md="auto">
                                <div class="w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep">공급유형</div>
                            </v-col>
                            <v-col cols="12" md="auto">
                                <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">{{ SUPPLY_CATEGORY[application?.supply_category]?.text }}</div>
                            </v-col>
                        </v-row>
                        <v-divider />
                    </v-sheet>
                </v-col>

                <!-- 특별공급 -->
                <template v-if="isSpecialType">
                    <v-col cols="6" class="border-r">
                        <v-sheet class="d-flex flex-column" style="flex: 1 0 auto">
                            <v-row no-gutters align="center" class="txt txt--sm">
                                <v-col cols="12" md="auto">
                                    <div class="w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep">소득순위</div>
                                </v-col>
                                <v-col cols="12" md="auto">
                                    <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">{{ RANKING_LIST[application.earning_rank]?.text }}</div>
                                </v-col>
                            </v-row>
                            <v-divider />
                        </v-sheet>
                    </v-col>
                    <v-col cols="6">
                        <v-sheet class="d-flex flex-column" style="flex: 1 0 auto">
                            <v-row no-gutters align="center" class="txt txt--sm">
                                <v-col cols="12" md="auto">
                                    <div class="w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep">공급타입</div>
                                </v-col>
                                <v-col cols="12" md="auto">
                                    <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">{{ SUPPLY_TYPE[application.supply_type]?.text }}</div>
                                </v-col>
                            </v-row>
                            <v-divider />
                        </v-sheet>
                    </v-col>
                    <v-col cols="6" class="border-r">
                        <v-sheet class="d-flex flex-column" style="flex: 1 0 auto">
                            <v-row no-gutters align="center" class="txt txt--sm">
                                <v-col cols="12" md="auto">
                                    <div class="w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep">지역순위</div>
                                </v-col>
                                <v-col cols="12" md="auto">
                                    <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">{{ RANKING_LIST[application.region_rank]?.text }}</div>
                                </v-col>
                            </v-row>
                            <v-divider />
                        </v-sheet>
                    </v-col>
                    <v-col cols="6">
                        <v-sheet class="d-flex flex-column" style="flex: 1 0 auto">
                            <v-row no-gutters align="center" class="txt txt--sm">
                                <v-col cols="12" md="auto">
                                    <div class="w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep">자동차가액 충족 여부</div>
                                </v-col>
                                <v-col cols="12" md="auto">
                                    <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">{{ application.isCar ? "예" : "아니요" }}</div>
                                </v-col>
                            </v-row>
                            <v-divider />
                        </v-sheet>
                    </v-col>
                </template>

                <!-- 일반공급 -->
                <template v-else>
                    <v-col cols="6" class="border-r">
                        <v-sheet class="d-flex flex-column" style="flex: 1 0 auto">
                            <v-row no-gutters align="center" class="txt txt--sm">
                                <v-col cols="12" md="auto">
                                    <div class="w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep">자동차가액 충족 여부</div>
                                </v-col>
                                <v-col cols="12" md="auto">
                                    <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">{{ application.isCar ? "예" : "아니요" }}</div>
                                </v-col>
                            </v-row>
                            <v-divider />
                        </v-sheet>
                    </v-col>
                    <v-col cols="6">
                        <v-sheet class="d-flex flex-column" style="flex: 1 0 auto">
                            <v-row no-gutters align="center" class="txt txt--sm">
                                <v-col cols="12" md="auto">
                                    <div class="w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep">공급타입</div>
                                </v-col>
                                <v-col cols="12" md="auto">
                                    <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">{{ SUPPLY_TYPE[application.supply_type]?.text }}</div>
                                </v-col>
                            </v-row>
                            <v-divider />
                        </v-sheet>
                    </v-col>
                </template>
            </v-row>

            <div class="btn-wrap">
                <v-row class="row--xs" justify="center">
                    <v-col cols="auto">
                        <v-btn x-large tile class="v-btn--width-fixed white--text" color="black" @click="go">신청서 수정</v-btn>
                    </v-col>
                </v-row>
            </div>
        </page-section-primary>
    </app-primary>
</template>

<script>
import api from "@/api";
import { SUPPLY_CATEGORY, initApplication, QUALIFICATION_TYPE, RANKING_LIST, SUPPLY_TYPE } from "@/assets/variables";
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadSecondary from "@/sets/parents/pages/sub-head-secondary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";

import TitWrap from "@/components/publish/styles/typography/tit-wrap.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

import SheetPrimary from "@/components/publish/parents/sheets/sheet-primary.vue";

export default {
    components: {
        AppPrimary,
        SubHeadSecondary,
        PageSectionPrimary,

        TitWrap,
        Tit,
        Txt,

        SheetPrimary,
    },
    props: {
        _application: { type: String, default: "" },
    },
    data: () => ({
        QUALIFICATION_TYPE,
        SUPPLY_CATEGORY,
        RANKING_LIST,
        SUPPLY_TYPE,
        application: initApplication(),
    }),
    computed: {
        isSpecialType() {
            return this.application.supply_category === SUPPLY_CATEGORY.SPECIAL_TYPE.value;
        },
    },
    mounted() {
        if (!this.$store.state.ci) {
            alert("본인인증이 필요합니다.");
            this.$router.go(-1);
        } else {
            this.init();
        }
    },
    methods: {
        init() {
            if (!this._application) {
                alert("잘못된 요청입니다.");
                this.$router.go(-1);
            }
            this.search();
        },
        async search() {
            try {
                let { application } = await api.v1.application.get({ _id: this._application });
                if(!application) {
                    alert("접수된 신청서가 없습니다");
                    this.$router.go(-1);
                }
                this.application = initApplication(application);
            } catch (error) {
                console.error(error);
            }
        },
        birthFormat(birth) {
            return (birth?.length === 6 ? (birth[0] <= "2" ? "20" : "19") + birth : birth?.slice(0, 4)) + ". " + birth?.slice(-4, -2) + ". " + birth?.slice(-2) + ".";
        },
        go() {
            try{
                const today = this.$dayjs();
                const deadline = this.$dayjs("2025-01-09 23:59:59");
                // tempBlock
                if(today.isAfter(deadline)) throw new Error("접수 및 수정은 01월 09일 23시 59분까지만 가능합니다.");
                this.$router.push(`/form/application/${this._application}`);
            } catch(error) {
                alert(error.message)
            }
        },
    },
};
</script>
