var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-container"
  }, [_c('div', {
    staticClass: "pb-8px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('tit-tertiary', [_vm._v("고객정보")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt-primary', {
    staticClass: "txt--sm"
  }, [_c('span', {
    staticClass: "pink--text"
  }, [_vm._v("*")]), _vm._v(" 필수입력 항목입니다.")])], 1)], 1)], 1), _c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('vertical-form-table', _vm._b({
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    items: _vm.computedItems
  }, false), [_c('template', {
    slot: "배우자 정보"
  }, [_c('v-row', {
    staticClass: "inner-form",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-140px txt--dark font-weight-medium pt-16px pb-4px px-10px px-md-20px py-md-16px"
  }, [_vm._v("이름 "), _c('span', {
    staticClass: "red--text"
  }, [_vm._v("*")])])]), _c('v-col', [_c('div', {
    staticClass: "pt-4px pb-16px px-10px px-md-20px py-md-16px"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "auto",
      "placeholder": "이름을 입력하세요.",
      "rules": _vm.rules.name
    },
    model: {
      value: _vm.form.spouse.name,
      callback: function ($$v) {
        _vm.$set(_vm.form.spouse, "name", $$v);
      },
      expression: "form.spouse.name"
    }
  })], 1)])], 1), _c('v-divider'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-140px txt--dark font-weight-medium pt-16px pb-4px px-10px px-md-20px py-md-16px"
  }, [_vm._v("연락처 "), _c('span', {
    staticClass: "red--text"
  }, [_vm._v("*")])])]), _c('v-col', [_c('div', {
    staticClass: "pt-4px pb-16px px-10px px-md-20px py-md-16px"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "auto",
      "placeholder": "'-'를 제외하고 입력하세요.",
      "rules": _vm.rules.phone
    },
    model: {
      value: _vm.form.spouse.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form.spouse, "phone", $$v);
      },
      expression: "form.spouse.phone"
    }
  })], 1)])], 1), _c('v-divider'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-140px txt--dark font-weight-medium pt-16px pb-4px px-10px px-md-20px py-md-16px"
  }, [_vm._v("생년월일"), _c('span', {
    staticClass: "red--text"
  }, [_vm._v("*")])])]), _c('v-col', [_c('div', {
    staticClass: "pt-4px pb-16px px-10px px-md-20px py-md-16px"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "auto",
      "placeholder": "생년월일(YYYYMMDD)을 입력하세요.",
      "rules": _vm.rules.birth
    },
    model: {
      value: _vm.form.spouse.birth,
      callback: function ($$v) {
        _vm.$set(_vm.form.spouse, "birth", $$v);
      },
      expression: "form.spouse.birth"
    }
  })], 1)])], 1)], 1)], 1)], 1), _c('template', {
    slot: "신청자격"
  }, [_c('v-radio-group', {
    attrs: {
      "hide-details": "auto",
      "row": ""
    },
    on: {
      "change": function (value) {
        return _vm.emit({
          value,
          type: 'qualification'
        });
      }
    },
    model: {
      value: _vm.form.qualification_type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "qualification_type", $$v);
      },
      expression: "form.qualification_type"
    }
  }, _vm._l(Object.values(_vm.QUALIFICATION_TYPE), function (item, index) {
    return _c('v-radio', {
      key: index,
      attrs: {
        "outlined": "",
        "color": "tertiary",
        "value": item.value,
        "label": item.text
      }
    });
  }), 1)], 1), _c('template', {
    slot: "소득순위"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "hide-details": "auto",
      "row": ""
    },
    on: {
      "change": function (value) {
        return _vm.emit({
          value,
          type: 'earning_rank'
        });
      }
    },
    model: {
      value: _vm.form.earning_rank,
      callback: function ($$v) {
        _vm.$set(_vm.form, "earning_rank", $$v);
      },
      expression: "form.earning_rank"
    }
  }, _vm._l(Object.values(_vm.RANKING_LIST), function (item, index) {
    return _c('v-radio', {
      key: index,
      attrs: {
        "outlined": "",
        "color": "tertiary",
        "value": item.value,
        "label": item.text
      }
    });
  }), 1)], 1), _c('v-col', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("1순위")]), _c('v-col', [_vm._v(": 2023년도 기준소득 100% 이하 ")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("2순위")]), _c('v-col', [_vm._v(": 2023년도 기준소득 110% 이하")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("3순위")]), _c('v-col', [_vm._v(": 2023년도 기준소득 120% 이하")])], 1)], 1)], 1)], 1), _c('template', {
    slot: "지역순위"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "hide-details": "auto",
      "row": ""
    },
    on: {
      "change": function (value) {
        return _vm.emit({
          value,
          type: 'region_rank'
        });
      }
    },
    model: {
      value: _vm.form.region_rank,
      callback: function ($$v) {
        _vm.$set(_vm.form, "region_rank", $$v);
      },
      expression: "form.region_rank"
    }
  }, _vm._l(Object.values(_vm.RANKING_LIST), function (item, index) {
    return _c('v-radio', {
      key: index,
      attrs: {
        "outlined": "",
        "color": "tertiary",
        "value": item.value,
        "label": item.text
      }
    });
  }), 1)], 1), _c('v-col', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("1순위")]), _c('v-col', [_vm._v(": 해당 공급 대상 주택 소재지(서울특별시 은평구) ")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("2순위")]), _c('v-col', [_vm._v(": 해당 공급 대상 주택 소재지 외(서울특별시)")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("3순위")]), _c('v-col', [_vm._v(": 그 외 지역")])], 1)], 1)], 1)], 1), _c('template', {
    slot: "무주택기준 충족 여부"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "hide-details": "auto",
      "row": ""
    },
    on: {
      "change": function (value) {
        return _vm.emit({
          value,
          type: 'isHomeless'
        });
      }
    },
    model: {
      value: _vm.form.isHomeless,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isHomeless", $$v);
      },
      expression: "form.isHomeless"
    }
  }, _vm._l(Object.values(_vm.BOOLEAN_LIST), function (item, index) {
    return _c('v-radio', {
      key: index,
      attrs: {
        "outlined": "",
        "color": "tertiary",
        "value": item.value,
        "label": item.text
      }
    });
  }), 1)], 1), _c('v-col', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("1.")]), _c('v-col', [_vm._v("‘무주택자’란 주택을 소유하고 있지 않은 자를 말합니다.(청년, 예비신혼부부해당)")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("2.")]), _c('v-col', [_vm._v("“무주택세대구성원”이란 전원이 주택을 소유하고 있지 않은 세대의 구성원을 말합니다. (신혼부부해당)")])], 1)], 1)], 1)], 1), _c('template', {
    slot: "자산기준 충족 여부"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "hide-details": "auto",
      "row": ""
    },
    on: {
      "change": function (value) {
        return _vm.emit({
          value,
          type: 'isAsset'
        });
      }
    },
    model: {
      value: _vm.form.isAsset,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isAsset", $$v);
      },
      expression: "form.isAsset"
    }
  }, _vm._l(Object.values(_vm.BOOLEAN_LIST), function (item, index) {
    return _c('v-radio', {
      key: index,
      attrs: {
        "outlined": "",
        "color": "tertiary",
        "value": item.value,
        "label": item.text
      }
    });
  }), 1)], 1), _c('v-col', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("1.")]), _c('v-col', [_vm._v("(청년) 2024년 기준 본인 자산가액 2억7,300만원 이하 시 \"예\" 체크")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("2.")]), _c('v-col', [_vm._v("(신혼부부) 2024년 기준 세대(예비 신혼부부의 경우 구성될)의 총 자산가액 3억4,500만원 이하 시 \"예\"체크")])], 1)], 1)], 1)], 1), _c('template', {
    slot: "자동차가액 충족 여부"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "hide-details": "auto",
      "row": ""
    },
    on: {
      "change": function (value) {
        return _vm.emit({
          value,
          type: 'isCar'
        });
      }
    },
    model: {
      value: _vm.form.isCar,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isCar", $$v);
      },
      expression: "form.isCar"
    }
  }, _vm._l(Object.values(_vm.BOOLEAN_LIST), function (item, index) {
    return _c('v-radio', {
      key: index,
      attrs: {
        "outlined": "",
        "color": "tertiary",
        "value": item.value,
        "label": item.text
      }
    });
  }), 1)], 1), _c('v-col', [_c('span', [_vm._v("2024년 기준 차량기준가액 3,708만원 이내 시 \"예\"체크")])])], 1)], 1)], 2), _c('div', {
    staticClass: "mt-20px mt-lg-40px"
  }, [_c('terms-of-agreements-short', {
    attrs: {
      "params": {
        codes: ['collect-use']
      }
    },
    on: {
      "isValid": function (isValid) {
        return _vm.isTermsValid = isValid;
      },
      "input": _vm.emitTerms
    },
    model: {
      value: _vm.form._terms,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_terms", $$v);
      },
      expression: "form._terms"
    }
  })], 1), _c('sheet-primary', {
    staticClass: "text-center my-40px my-lg-60px py-30px py-lg-40px"
  }, [_c('txt-quarternary', [_vm._v(" 모집공고 전문을 반드시 숙지 후 청약신청을 진행해주시기 바라며, "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 미숙지로 인한 착오 신청 등에 대해서는 "), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v("신청자 본인에게 책임이 있으니 유의하여 주시기 비랍니다.")])])], 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100 min-w-sm-160px",
    attrs: {
      "tile": "",
      "x-large": "",
      "outlined": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("취소")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "w-100 min-w-sm-160px",
    attrs: {
      "tile": "",
      "x-large": "",
      "color": "grey darken-3"
    },
    on: {
      "click": _vm.validates
    }
  }, 'v-btn', {
    disabled: _vm.disabled,
    loading: _vm.loading
  }, false), [_c('span', {
    staticClass: "white--text"
  }, [_vm._v(_vm._s(_vm.text))])])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }