var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "청약안내",
            "tabActive": "청약일정",
            "bg": "/images/sub/subscription/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("청약안내")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("청약일정")];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    staticClass: "row--xl"
  }, [_c('v-col', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "md": "4",
      "lg": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/subscription/schedule/schedule.jpg"
    }
  })], 1), _c('v-col', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "schedule-card",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "secondary"
    }
  }, [_c('tit-tertiary', [_vm._v("루미노 816 청약 일정 안내")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "schedule-card",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "grey lighten-5"
    }
  }, [_c('txt-quarternary', [_c('span', {
    staticClass: "grey--text text--darken-4 mr-10px font-weight-bold"
  }, [_vm._v("입주자 모집공고")]), _vm._v("2025.01.02")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "schedule-card",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "grey lighten-5"
    }
  }, [_c('txt-quarternary', [_c('span', {
    staticClass: "grey--text text--darken-4 mr-10px font-weight-bold"
  }, [_vm._v("청약신청")]), _vm._v("2025.01.07 ~ 01.09 인터넷 접수시스템")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "schedule-card",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "grey lighten-5"
    }
  }, [_c('txt-quarternary', [_c('span', {
    staticClass: "grey--text text--darken-4 mr-10px font-weight-bold"
  }, [_vm._v("서류심사 대상자 발표(당첨자 및 동호수 발표)")]), _vm._v("2025.01.13")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "schedule-card schedule-card",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "grey lighten-5"
    }
  }, [_c('txt-quarternary', [_c('span', {
    staticClass: "grey--text text--darken-4 mr-10px font-weight-bold"
  }, [_vm._v("당첨자 서류 사본 제출(이메일)")]), _vm._v("2025.01.14 ~ 01.16")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "schedule-card schedule-card",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "grey lighten-5"
    }
  }, [_c('txt-quarternary', [_c('span', {
    staticClass: "grey--text text--darken-4 mr-10px font-weight-bold"
  }, [_vm._v("입주자 사전점검")]), _vm._v("계약체결일 계약서 작성 전 가능")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "schedule-card schedule-card__last",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "grey lighten-5"
    }
  }, [_c('txt-quarternary', [_c('span', {
    staticClass: "grey--text text--darken-4 mr-10px font-weight-bold"
  }, [_vm._v("계약체결 및 원본서류제출")]), _vm._v("25.01.19 ~ 01.22 ")])], 1)], 1)], 1), _c('txt-primary', {
    staticClass: "mt-12px mt-md-16px"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v("현장 여건상 일정이 변경될 수 있으며, 변경 시 별도로 안내될 예정입니다.")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v("청약접수는 당사 홈페이지 https://www.lumino816.com/ 에서 신청 가능합니다.")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v("인터넷 청약신청 기간 : 2025. 01. 07. ~ 2025. 01. 09. (24시간 가능)")])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }