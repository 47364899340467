<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="청약안내" tabActive="청약일정" bg="/images/sub/subscription/visual.jpg">
                <template #title>청약안내</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>청약일정</template>

            <v-row class="row--xl">
                <v-col md="4" lg="auto" class="d-none d-lg-block">
                    <v-img src="/images/sub/subscription/schedule/schedule.jpg"></v-img>
                </v-col>
                <v-col>
                    <v-row>
                        <v-col cols="12">
                            <v-card flat tile color="secondary" class="schedule-card">
                                <tit-tertiary>루미노 816 청약 일정 안내</tit-tertiary>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card flat tile color="grey lighten-5" class="schedule-card">
                                <txt-quarternary> <span class="grey--text text--darken-4 mr-10px font-weight-bold">입주자 모집공고</span>2025.01.02</txt-quarternary>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card flat tile color="grey lighten-5" class="schedule-card">
                                <txt-quarternary> <span class="grey--text text--darken-4 mr-10px font-weight-bold">청약신청</span>2025.01.07 ~ 01.09 인터넷 접수시스템</txt-quarternary>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card flat tile color="grey lighten-5" class="schedule-card">
                                <txt-quarternary> <span class="grey--text text--darken-4 mr-10px font-weight-bold">서류심사 대상자 발표(당첨자 및 동호수 발표)</span>2025.01.13</txt-quarternary>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card flat tile color="grey lighten-5" class="schedule-card schedule-card">
                                <txt-quarternary> <span class="grey--text text--darken-4 mr-10px font-weight-bold">당첨자 서류 사본 제출(이메일)</span>2025.01.14 ~ 01.16</txt-quarternary>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card flat tile color="grey lighten-5" class="schedule-card schedule-card">
                                <txt-quarternary> <span class="grey--text text--darken-4 mr-10px font-weight-bold">입주자 사전점검</span>계약체결일 계약서 작성 전 가능</txt-quarternary>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card flat tile color="grey lighten-5" class="schedule-card schedule-card__last">
                                <txt-quarternary> <span class="grey--text text--darken-4 mr-10px font-weight-bold">계약체결 및 원본서류제출</span>25.01.19 ~ 01.22 </txt-quarternary>
                            </v-card>
                        </v-col>
                    </v-row>
                    <txt-primary class="mt-12px mt-md-16px">
                        <v-row class="row--xxs">
                            <v-col cols="auto">※</v-col>
                            <v-col>현장 여건상 일정이 변경될 수 있으며, 변경 시 별도로 안내될 예정입니다.</v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">※</v-col>
                            <v-col>청약접수는 당사 홈페이지 https://www.lumino816.com/ 에서 신청 가능합니다.</v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">※</v-col>
                            <v-col>인터넷 청약신청 기간 : 2025. 01. 07. ~ 2025. 01. 09. (24시간 가능)</v-col>
                        </v-row>
                    </txt-primary>
                </v-col>
            </v-row>
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import TitTertiary from "@/components/publish/parents/typography/tit-tertiary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import TxtQuarternary from "@/components/publish/parents/typography/txt-quarternary.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        TitTertiary,
        TxtPrimary,
        TxtQuarternary,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.schedule-card {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        width: 1px;
        height: 30px;
        background-color: var(--v-secondary-base);
        transform: translateY(100%);
    }
    &__last::before {
        display: none;
    }
}
@media (min-width: 768px) {
    .schedule-card {
        padding: 40px 0;
    }
}
</style>
